import React from 'react'

// Styling
import './case-list.scss'

// Components
import { CasePostProps } from './Case'

interface Props {
  children: React.ReactElement<CasePostProps>[]
}

export const Cases: React.FC<Props> = ({ children }) => {
  console.log(children)
  const count = React.Children.count(children)

  if (count === 0) {
    return null
  }

  const elements = React.Children.map(children, (child, index) => {
    if (!React.isValidElement(child)) {
      return null
    }

    switch (index) {
      case 0: {
        const newProps: CasePostProps = {
          ...child.props,
          sizeType: {
            size: 'large-image-right',
          },
        }
        return React.cloneElement(child, newProps)
      }
      case 1: {
        const newProps: CasePostProps = {
          ...child.props,
          sizeType: {
            size: 'large-image-left',
          },
        }
        return React.cloneElement(child, newProps)
      }
      default:
        const newProps: CasePostProps = {
          ...child.props,
          sizeType: {
            size: 'normal',
          },
        }
        return React.cloneElement(child, newProps)
    }
  })

  if (count === 1) {
    return (
      <div id="case-posts" className="case-List">
        {children}
      </div>
    )
  } else {
    return (
      <div id="case-posts" className="case-List">
        {elements}
      </div>
    )
  }
}
