import React, { useState } from 'react'
import { graphql, PageRendererProps } from 'gatsby'
import { get } from 'lodash/fp'

// Components
import Layout from 'components/layout'
import { Blocks } from 'components/Blocks'
import SEO from 'components/seo'
import { Case, Cases } from 'components/CasePosts'

//styles
import './caseTemplate.scss'

//types
import { ContentfulCase, ContentfulPage } from 'types/contentful'

// Types
type PageContext = {
  baseSlug: string
  slug: string
}

type CaseEdge = {
  node: Partial<ContentfulCase>
}

interface Props extends PageRendererProps {
  data: {
    page: ContentfulPage
    cases: {
      edges: CaseEdge[]
    }
    site: {
      siteMetadata: {
        siteUrl: string
      }
    }
  }
  pageContext: PageContext
}

const CaseIndexTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const { page, cases } = data
  const theme = page.theme ? page.theme : 'Light'
  const seoTitle = page.seoTitle ? page.seoTitle : page.title
  const { baseSlug } = pageContext
  const pageExcerpt = get('page.excerpt.excerpt', data)

  cases.edges.sort((a, b) => {
    return new Date(b.node.createdAt) - new Date(a.node.createdAt)
  })

  return (
    <Layout theme={theme}>
      <SEO title={seoTitle} description={pageExcerpt}></SEO>

      <div
        className={'caseHeroMain block block--hero hero header-light'}
        style={{ background: '#313341' }}
      >
        <div className="caseHeroMain__title">
          <h1 className="hero__title hero--white">{page.title}</h1>
        </div>
        <div className={'caseHeroMain__content'}>
          <div className={'hero__text hero--white'}>{page.excerpt.excerpt}</div>
        </div>
      </div>

      <Blocks blocks={page.blocks} />
      <Cases>
        {cases.edges.map(({ node }) => (
          <Case
            key={node.slug}
            prefixUrl={baseSlug}
            casePost={node as ContentfulCase}
          />
        ))}
      </Cases>
    </Layout>
  )
}
export default CaseIndexTemplate

export const query = graphql`
  query CasePageQuery($baseSlug: String!) {
    page: contentfulPage(slug: { eq: $baseSlug }) {
      title
      seoTitle
      theme
      excerpt {
        excerpt
      }
      blocks {
        ... on ContentfulBlock {
          ...BlocksFragment
        }
      }
    }
    cases: allContentfulCase {
      edges {
        node {
          featuredMedia {
            gatsbyImageData(formats: [AUTO], placeholder: BLURRED)
          }
          client
          tasks
          heading
          excerpt {
            excerpt
          }
          category
          id
          title
          slug
          createdAt
          updatedAt
        }
      }
    }
  }
`
