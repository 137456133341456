import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

//types
import { ContentfulCase } from 'types/contentful'

// Styling
import './case.scss'

type Sizes = 'normal' | 'large-image-right' | 'large-image-left'

export interface SizeConfig {
  size: Sizes
}

export interface CasePostProps {
  casePost: ContentfulCase
  sizeType?: SizeConfig | false
  prefixUrl?: string
}

export const Case: React.FC<CasePostProps> = ({
  casePost,
  sizeType,
  prefixUrl,
}) => {
  const href = prefixUrl
    ? `/${prefixUrl}/${casePost.slug}`
    : `/${casePost.slug}`
  const cardSize = sizeType ? sizeType.size : 'normal'
  const classes = classNames('caseCard card--case', {
    [`card--case-${cardSize}`]: sizeType && sizeType.size,
  })

  return (
    <div className={classes}>
      {casePost.featuredMedia?.gatsbyImageData && (
        <div className="case__image">
          <Link to={href} title={`Läs om ${casePost.title}`}>
            <GatsbyImage
              alt={casePost.client}
              image={casePost.featuredMedia.gatsbyImageData}
              className="caseCard__image"
            />
          </Link>
        </div>
      )}
      <div className="caseCard__content">
        <span className="caseCard__client">
          {casePost.client} | {casePost.category}
        </span>
        <h3 className="caseCard__heading">{casePost.heading}</h3>
        <p className="caseCard__excerpt">{casePost.excerpt.excerpt}</p>
        <span className="caseCard__tasks">
          {casePost.tasks?.map((task, index, arr) => (
            <span key={index}>
              {task}
              {index != arr.length - 1 ? ', ' : ''}
            </span>
          ))}
        </span>
      </div>
    </div>
  )
}
Case.defaultProps = {
  sizeType: false,
  prefixUrl: '',
}
